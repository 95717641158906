import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useStaticQuery, graphql } from 'gatsby'

const query = graphql`
  query StaticTemplateQuery {
    allContentfulNavigationMenu {
      edges {
        node {
          type
          node_locale
          navigationItems {
            internal {
              content
            }
          }
        }
      }
    }
  }
`

const StaticTemplate = ({ children }) => {
  const [_t, i18n] = useTranslation()
  const allMenus = useStaticQuery(query)

  const {
    allContentfulNavigationMenu: { edges },
  } = allMenus

  const navMenus = edges.reduce((acc, edge) => {
    const {
      node: {
        type,
        // eslint-disable-next-line camelcase
        node_locale,
        navigationItems: {
          internal: { content },
        },
      },
    } = edge

    // Since the content is received as a json string, we need to parse it
    const menu = JSON.parse(content)
    acc[node_locale] = acc[node_locale] || {}
    acc[node_locale][type] = menu.items

    return acc
  }, {})

  return children({ navMenus: navMenus[i18n.language] })
}

StaticTemplate.propTypes = {
  children: PropTypes.func,
  location: PropTypes.object,
}

export { StaticTemplate }
export default StaticTemplate
