import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Heading } from '../components/typography/Heading'
import { Layout } from '../components/layouts/layout'
import { Container, Row, Col } from '../components/grid'
import { Eyebrow } from '../components/typography/Eyebrow'
import { Link } from '../components/typography/Link'
import { Stack } from '../components/stack'
import { PageMeta } from '../components/page-meta'
import { StaticTemplate } from '../templates/staticTemplate'
import * as styles from './404.module.scss'

const NotFoundPage = ({ location, pageContext }) => {
  const [t] = useTranslation()
  return (
    <StaticTemplate pageContext={pageContext}>
      {({ navMenus }) => (
        <Layout
          location={location}
          pageContext={{ ...pageContext, pathname: '/404' }}
          menu={navMenus}
        >
          <PageMeta
            language={pageContext.langKey}
            title={t('NotFoundPage.pageTitle', { defaultValue: '404 Error (Not Found)' })}
            pathname="/404"
          />

          <Container>
            <Row>
              <Col xs={12} lg={6}>
                <div className={styles.LeftBlock}>
                  <Eyebrow className={styles.NotFoundEyebrow}>
                    {t('NotFoundPage.tagline', { defaultValue: '404 Error' })}
                  </Eyebrow>
                  <Heading element="h1" size={2} className={styles.NotFoundHeadline}>
                    {t('NotFoundPage.headline', {
                      defaultValue:
                        "We're sorry — you've reached a page that has moved or is no longer available.",
                    })}
                  </Heading>
                </div>
              </Col>

              <Col xs={12} lg={6}>
                <div className={styles.RightBlock}>
                  <Heading element="h2" size={4} className={styles.LinksHeadline}>
                    {t('NotFoundPage.doubleCheck', {
                      defaultValue:
                        'Please double-check the URL, otherwise this page was probably moved or removed.',
                    })}
                  </Heading>

                  <Heading element="h3" size={6} className={styles.LinksSubheadline}>
                    {t('NotFoundPage.tryingToReach', {
                      defaultValue: 'You might be trying to reach',
                    })}
                    :
                  </Heading>

                  <Stack vertical>
                    {navMenus.not_found.map(navItem => (
                      <Link key={navItem.title} to={navItem.urlPath} className={styles.HelpfulLink}>
                        {navItem.title}
                      </Link>
                    ))}
                  </Stack>
                </div>
              </Col>
            </Row>
          </Container>
        </Layout>
      )}
    </StaticTemplate>
  )
}

NotFoundPage.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
  pageContext: PropTypes.object,
}

export { NotFoundPage }
export default NotFoundPage
